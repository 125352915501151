*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@keyframes closeWindow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ril__outer {
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
  background-color: #000000d9;
  outline: none;
  inset: 0;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  inset: 0;
}

.ril__image, .ril__imagePrev, .ril__imageNext {
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
  margin: auto;
  position: absolute;
  inset: 0;
}

.ril__imageDiscourager {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.ril__navButtons {
  width: 20px;
  height: 34px;
  cursor: pointer;
  opacity: .7;
  border: none;
  margin: auto;
  padding: 40px 30px;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ril__navButtons:hover {
  opacity: 1;
}

.ril__navButtons:active {
  opacity: .7;
}

.ril__navButtonPrev {
  background: #0003 url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==") center no-repeat;
  left: 0;
}

.ril__navButtonNext {
  background: #0003 url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+") center no-repeat;
  right: 0;
}

.ril__downloadBlocker {
  background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  background-size: cover;
  position: absolute;
  inset: 0;
}

.ril__caption, .ril__toolbar {
  background-color: #00000080;
  justify-content: space-between;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.ril__caption {
  max-height: 150px;
  bottom: 0;
  overflow: auto;
}

.ril__captionContent {
  color: #fff;
  padding: 10px 20px;
}

.ril__toolbar {
  height: 50px;
  top: 0;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  text-overflow: ellipsis;
  flex: 0 auto;
  padding-left: 20px;
  padding-right: 0;
  overflow: hidden;
}

.ril__toolbarRightSide {
  flex: none;
  padding-left: 0;
  padding-right: 20px;
}

.ril__toolbarItem {
  color: #fff;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  font-size: 120%;
  line-height: 50px;
  display: inline-block;
  overflow: hidden;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  opacity: .7;
  border: none;
}

.ril__builtinButton:hover {
  opacity: 1;
}

.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: .5;
}

.ril__builtinButtonDisabled:hover {
  opacity: .5;
}

.ril__closeButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=") center no-repeat;
}

.ril__zoomInButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+") center no-repeat;
}

.ril__zoomOutButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=") center no-repeat;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%, 19.999%, 100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ril__loadingCirclePoint:before {
  content: "";
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  margin: 0 auto;
  animation: .8s ease-in-out infinite both pointFade;
  display: block;
}

.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0);
}

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ril__loadingCirclePoint:nth-of-type(1):before, .ril__loadingCirclePoint:nth-of-type(7):before {
  animation-delay: -800ms;
}

.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}

.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}

.ril__loadingCirclePoint:nth-of-type(2):before, .ril__loadingCirclePoint:nth-of-type(8):before {
  animation-delay: -666ms;
}

.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}

.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}

.ril__loadingCirclePoint:nth-of-type(3):before, .ril__loadingCirclePoint:nth-of-type(9):before {
  animation-delay: -533ms;
}

.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}

.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}

.ril__loadingCirclePoint:nth-of-type(4):before, .ril__loadingCirclePoint:nth-of-type(10):before {
  animation-delay: -400ms;
}

.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}

.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}

.ril__loadingCirclePoint:nth-of-type(5):before, .ril__loadingCirclePoint:nth-of-type(11):before {
  animation-delay: -266ms;
}

.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}

.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}

.ril__loadingCirclePoint:nth-of-type(6):before, .ril__loadingCirclePoint:nth-of-type(12):before {
  animation-delay: -133ms;
}

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}

.ril__loadingCirclePoint:nth-of-type(7):before, .ril__loadingCirclePoint:nth-of-type(13):before {
  animation-delay: 0s;
}

.ril__loadingContainer {
  position: absolute;
  inset: 0;
}

.ril__imagePrev .ril__loadingContainer, .ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.ril__imagePrev .ril__errorContainer, .ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

/*# sourceMappingURL=index.b1e7c3b6.css.map */
